.assessment-page
  .qu-topmenu
    background-color: $primary-light

    .nav-link, .dropdown-menu .dropdown-item
      &:hover
        color: $primary

    .nav-item .active
      border-bottom: 3px solid $primary

    .dropdown-menu
      background-color: $primary-light

      .dropdown-item
        border-left: 3px solid $primary-light
        background-color: $primary-light

      .dropdown-item.active
        border-left: 3px solid $primary
        background-color: $primary-light

  .qu-sidemenu
    .navbar
      .active
        background-color: lighten($primary-light, 52%)
        font-weight: bold

.audit-page
  .qu-topmenu
    background-color: $gray-500

    .nav-link, .dropdown-menu .dropdown-item
      &:hover
        color: $dark-gray

    .nav-item .active
      border-bottom: 3px solid $dark-gray

    .dropdown-menu
      background-color: $gray-500

      .dropdown-item
        border-left: 3px solid $gray-500
        background-color: $gray-500

      .dropdown-item.active
        border-left: 3px solid $dark-gray
        background-color: $gray-500

  .qu-sidemenu
    .navbar
      .active
        background-color: lighten($gray-200, 2%)
        font-weight: bold

.target-maturity-answer-page
  .qu-topmenu
    background-color: darken($qu-ochre, 3%)

    .nav-link, .dropdown-menu .dropdown-item
      &:hover
        color: darken($qu-ochre, 25%)

    .nav-item .active
      border-bottom: 3px solid darken($qu-ochre, 25%)

    .dropdown-menu
      background-color: darken($qu-ochre, 3%)

      .dropdown-item
        border-left: 3px solid darken($qu-ochre, 3%)
        background-color: darken($qu-ochre, 3%)

      .dropdown-item.active
        border-left: 3px solid darken($qu-ochre, 25%)
        background-color: darken($qu-ochre, 3%)

  .qu-sidemenu
    .navbar
      .active
        background-color: lighten($qu-ochre, 38%)
        font-weight: bold

.default-page
  .qu-topmenu
    background-color: $dark-gray

    .nav-link, .dropdown-menu .dropdown-item
      &:hover
        color: $gray-400

    .nav-item .active
      border-bottom: 3px solid $gray-500

    .dropdown-menu
      background-color: $dark-gray

      .dropdown-item
        border-left: 3px solid $dark-gray
        background-color: $dark-gray

      .dropdown-item.active
        border-left: 3px solid $gray-500
        background-color: $dark-gray

  .qu-sidemenu
    .navbar
      .active
        background-color: lighten($gray-200, 2%)
        font-weight: bold
