.comment-badge
  display: inline-block
  letter-spacing: -0.0379166px
  white-space: nowrap
  overflow: hidden

  > *
    vertical-align: middle

.red-badge
  color: lighten($alert-light, 5%)
  background-color: $background-alert-lighter

.green-badge
  color: darken($dashboard-green, 5%)
  background-color: $background-success-light

.blue-badge
  color: $primary-light
  background-color: $background-blue-light
