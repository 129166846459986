.qu-sidemenu
  @extend .border-end
  @extend .bg-white
  position: sticky
  top: 0
  height: 100vh

  .navbar
    align-items: stretch

    .nav-link
      padding: .8rem 1rem
      color: $primary
      text-decoration: none

      &:visited
        color: $primary

      &:hover
        background-color: $gray-100
        color: shade-color($primary-light, 10%)
        text-decoration: none

    .sidemenu-collapse
      .nav-link
        padding-left: 2.5rem

    .qu-instance-name
      padding: 2rem 1rem
      font-weight: bold

.todo
  font-weight: bold
  color: $todo

a.todo
  color: $todo

  &:hover,
  &:active,
  &:focus
    color: $todo

/* -------- internet explorer only --------- */

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
  .qu-sidemenu
    width: $sidemenu-width
    position: absolute
    left: 0
    top: 60px
