.qu-topmenu
  .nav-link, .dropdown-menu .dropdown-item
    color: $white
    text-decoration: none

    &:hover
      background-color: transparent

  .user-avatar-name
    display: inline

  .dropdown-toggle::after
    display: none

  .dropdown-menu
    .dropdown-item
      padding: .8rem 1rem

    .dropdown-item.active
      border-bottom: 0px
