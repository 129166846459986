.qu-body
  min-height: 100vh
  background-color: $background-body

.qu-content
  min-height: 95vh
  padding-bottom: 5rem

.qu-grid
  display: grid
  grid-template-columns: $sidemenu-width auto

.qu-footer
  background-color: $secondary
