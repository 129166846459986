table
  margin-top: 1em

.table.table-borderless
  > thead
    border-bottom: 0px

  tr
    border-bottom: 0px

.table
  > thead
    border-bottom: 2px solid $secondary

  tr
    border-bottom: 1px solid lighten($secondary, 5%)

  .minimal
    width: 1%

tr.line-sector td:first-child
  font-weight: 700
