.border-off-white
  border-top: 1px solid $off-white
  width: 100%

a, .assessmentAnswer-toggle
  color: $primary
  text-decoration: none

  &:visited
    color: $primary

  &:hover,
  &:active,
  &:focus
    color: shade-color($primary-light, 10%)
    text-decoration: none

.qu-shadow
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, .2)

.qu-body
  font-family: $system-fonts

  input, textarea, select, button, .btn
    &:focus, &:active
      outline: none
      box-shadow: 0 0 2px 0 lighten($primary-light, 20%)

.hidden
  display: none

.pre
  white-space: pre-wrap

h2 > small
  font-size: 0.5em
  top: -1em
  position: relative
  font-weight: normal

.success
  font-weight: bold
  color: $success

a.success
  color: $success

  &:hover,
  &:active,
  &:focus
    color: $success

.highlight
  background: $dashboard-ochre

.opacity-50
  opacity: 0.55

  &:hover
    opacity: 0.9


.opacity-75
  opacity: 0.75

  &:hover
    opacity: 1

.small-font
  font-size: .9rem

.big-font
  font-size: 1.1rem
