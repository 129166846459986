$primary: #02486d
$primary-light: #00a3da
$dark-gray: #4c5c5b
$qu-ochre: #d0d130
$secondary: #cccccc
$secondary-dark: grey
$light-gray: #f8f9fa
$background: #ffffff
$background-blue-light: #f1faff
$background-body: #f9f9f9
$background-box: #f7f7f9
$background-header: #4c5c5b
$font: #333333
$link: $primary
$link-active: $primary-light
$font-inverse: $background
$todo: #e18b00
$success: #198754
$alert: #dc3545
$alert-light: #f64e60
$not-applicable: $secondary-dark
$border: $secondary
$border-page: #f2f2f2
$border-page-bottom: #d0cfcf
$background-success: #D5F09A
$background-success-light: #e8fff3
$background-alert: #FF919E
$background-alert-light: #ffe2e5
$background-alert-lighter: #fff5f8
$off-white: #eef2f7
$dashboard-ochre: #ffbb00
$dashboard-green: #92c652
$dashboard-red: #d62d20

$font-size-small: 0.9em
$sidemenu-width: 260px

$enable-negative-margins: true

$system-fonts: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"
