.bg-primary-light
  background-color: $primary-light

.bg-primary-lighter
  background-color: lighten($primary-light, 53%)

.bg-gray-lighter
  background-color: lighten($off-white, 2%)

.bg-gray-200
  background-color: $gray-200

.bg-gray-300
  background-color: $gray-300

.bg-gray-500
  background-color: $gray-500

.bg-dark-gray
  background-color: $dark-gray

.bg-dark-ochre
  background-color: darken($qu-ochre, 3%)

.bg-ochre
  background-color: lighten($dashboard-ochre, 15%)

.bg-passed
  background-color: lighten($dashboard-green, 5%)

.bg-passed-light
  background-color: lighten($dashboard-green, 20%)

.bg-red
  background-color: lighten($dashboard-red, 10%)
