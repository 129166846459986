.lop-task-status
  width: 1.3rem
  height: 1.3rem
  border-radius: .25rem
  margin-top: .1rem

.lop-task-status-lg
  width: 1.6rem
  height: 1.6rem
  border-radius: .3rem
  position: relative
  top: .1rem

.position-task-icon
  right: 1.3rem
  top: 2.2rem

.position-task-icon-audit
  right: 1.3rem
  top: 4.3rem

.lop-task-result
  .other-status
    color: darken($border, 5%)
    border: 1px solid $border
    background-color: $background-box

  .due_date_exceeded:hover
    background-color: lighten($dashboard-red, 10%)
    border-color: lighten($dashboard-red, 10%)
    color: $white

  .in_progress:hover
    background-color: $gray-300
    border-color: $gray-300
    color: $dark

  .implemented:hover
    background-color: lighten($dashboard-ochre, 15%)
    border-color: lighten($dashboard-ochre, 15%)
    color: $dark

  .released:hover
    background-color: lighten($dashboard-green, 5%)
    border-color: lighten($dashboard-green, 5%)
    color: $dark

.non-conformities-assessments .assessmentAnswer-heading
  min-height: 4.8rem

.non-conformities-audits .assessmentAnswer-heading
  min-height: 6.8rem
